import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { FaCopyright, FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import { VscThreeBars, VscClose } from "react-icons/vsc";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";

import { LayoutProps } from "./Layout.props";
import {
  Header,
  Nav,
  Anchor,
  Main,
  Footer,
  Navigation,
  NavigationHeadContainer,
  NavigationFootContainer,
  LinkContainer,
  LogoContainer,
  AnchorContainer,
} from "./Layout.style";

import Button from "../component/Button";
import Text from "../component/Text";
import About from "../section/About";
import Contact from "../section/Contact";
import Home from "../section/Home";
import Project from "../section/Project";
import Skill from "../section/Skill";

const LayoutView = (props: LayoutProps): JSX.Element => {
  const isDesktopDevice = useMediaQuery({ minWidth: 992 });

  const bias = 64;
  const [y, setY] = useState<number>(0);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      <ToastContainer position="top-center" autoClose={3000} />
      <Header ref={(ref) => setY(ref?.offsetHeight || 0)}>
        <Nav>
          {isDesktopDevice ? (
            <>
              <LogoContainer>
                <Text>Yuhwan Ban</Text>
              </LogoContainer>
              <AnchorContainer>
                <Anchor href="/#home">
                  <Button color="transparent">Home</Button>
                </Anchor>
                <Anchor href="/#about">
                  <Button color="transparent">About</Button>
                </Anchor>
                <Anchor href="/#skill">
                  <Button color="transparent">Skill</Button>
                </Anchor>
                <Anchor href="/#project">
                  <Button color="transparent">Project</Button>
                </Anchor>
                <Anchor href="/#contact">
                  <Button color="transparent">Contact</Button>
                </Anchor>
              </AnchorContainer>
              <LinkContainer>
                <Anchor
                  href="https://www.linkedin.com/in/yuhwan-lucas-ban/"
                  target="_blank"
                >
                  <FaLinkedin color="midnightblue" size={32} />
                </Anchor>
                <Anchor href="https://github.com/laubana" target="_blank">
                  <FaGithub color="midnightblue" size={32} />
                </Anchor>
              </LinkContainer>
            </>
          ) : (
            <>
              <Button
                color="transparent"
                onClick={() => setIsVisible((oldValue) => !oldValue)}
              >
                <VscThreeBars />
              </Button>
              {isVisible && (
                <Navigation>
                  <NavigationHeadContainer>
                    <Button
                      color="transparent"
                      onClick={() => setIsVisible((oldValue) => !oldValue)}
                    >
                      <VscClose />
                    </Button>
                  </NavigationHeadContainer>
                  <NavigationFootContainer>
                    <Anchor href="/#home">
                      <Button color="transparent">Home</Button>
                    </Anchor>
                    <Anchor href="/#about">
                      <Button color="transparent">About</Button>
                    </Anchor>
                    <Anchor href="/#education">
                      <Button color="transparent">Education</Button>
                    </Anchor>
                    <Anchor href="/#experience">
                      <Button color="transparent">Experience</Button>
                    </Anchor>
                    <Anchor href="/#skill">
                      <Button color="transparent">Skill</Button>
                    </Anchor>
                    <Anchor href="/#project">
                      <Button color="transparent">Project</Button>
                    </Anchor>
                    <Anchor href="/#contact">
                      <Button color="transparent">Contact</Button>
                    </Anchor>
                    <LinkContainer>
                      <Anchor
                        href="https://www.linkedin.com/in/yuhwan-lucas-ban/"
                        target="_blank"
                      >
                        <FaLinkedin color="midnightblue" size={32} />
                      </Anchor>
                      <Anchor
                        href="https://www.instagram.com/zunda.the.green/"
                        target="_blank"
                      >
                        <FaInstagram color="midnightblue" size={32} />
                      </Anchor>
                      <Anchor href="https://github.com/laubana" target="_blank">
                        <FaGithub color="midnightblue" size={32} />
                      </Anchor>
                    </LinkContainer>
                  </NavigationFootContainer>
                </Navigation>
              )}
            </>
          )}
        </Nav>
      </Header>
      <Main style={{ marginTop: y }}>
        <Home style={{ paddingTop: y + bias }} />
        <About style={{ paddingTop: y + bias }} />
        <Skill style={{ paddingTop: y + bias }} />
        <Project style={{ paddingTop: y + bias }} />
        <Contact style={{ paddingTop: y + bias }} />
      </Main>
      <Footer>
        <Text sizing="small" alignment="center" style={{ gap: "8px" }}>
          <FaCopyright />
          2024, Yuhwan Ban
        </Text>
      </Footer>
    </>
  );
};

export default LayoutView;
