import React, { useEffect, useState } from "react";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

import { ProjectProps } from "./Project.props";
import {
  Container,
  ProjectContainer,
  Image,
  ImageContainer,
  MessageContainer,
  StackList,
  LinkContainer,
  Anchor,
  StackItem,
} from "./Project.style";

import Carousel from "../../component/Carousel";
import Text from "../../component/Text";

const ProjectView = (props: ProjectProps): JSX.Element => {
  const { style } = props;

  const isDesktopDevice = useMediaQuery({ minWidth: 992 });

  const multiplier = 0.000000001;
  const bias = isDesktopDevice ? 0 : 128;

  const [scrollY, setScrollY] = useState<number>(0);
  const [y4, setY4] = useState<number>(0);
  const [height4, setHeight4] = useState<number>(0);
  const [y3, setY3] = useState<number>(0);
  const [height3, setHeight3] = useState<number>(0);
  const [y2, setY2] = useState<number>(0);
  const [height2, setHeight2] = useState<number>(0);
  const [y1, setY1] = useState<number>(0);
  const [height1, setHeight1] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Container id="project" style={style}>
      <ProjectContainer
        ref={(ref) => {
          setY4(ref?.offsetTop || 0);
          setHeight4(ref?.offsetHeight || 0);
        }}
        style={{
          gridTemplateColumns: "2fr 1fr",
          flexDirection: "column-reverse",
        }}
      >
        <Carousel
          slides={[
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/lelebale1.png" />
            </ImageContainer>,
          ]}
          style={{
            transform: `translateX(${
              -multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y4 - (window.innerHeight - height4) / 2) - bias
                ),
                4
              )
            }%)`,
          }}
        />
        <MessageContainer
          style={{
            transform: `translateX(${
              multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y4 - (window.innerHeight - height4) / 2) + bias
                ),
                4
              )
            }%)`,
          }}
        >
          <Text bold style={{ fontSize: "48px" }}>
            Lele Bale
          </Text>
          <Text sizing="small">
            Platform for booking and managing event venues.
          </Text>
          <StackList>
            <StackItem>
              <Text sizing="small">TypeScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">JavaScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">HTML</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">CSS</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">React</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Node.js</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">MongoDB</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">PayMongo</Text>
            </StackItem>
          </StackList>
          <LinkContainer></LinkContainer>
        </MessageContainer>
      </ProjectContainer>
      <ProjectContainer
        ref={(ref) => {
          setY3(ref?.offsetTop || 0);
          setHeight3(ref?.offsetHeight || 0);
        }}
        style={{ gridTemplateColumns: "1fr 2fr", flexDirection: "column" }}
      >
        <MessageContainer
          style={{
            transform: `translateX(${
              -multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y3 - (window.innerHeight - height3) / 2) + bias
                ),
                4
              )
            }%)`,
          }}
        >
          <Text bold style={{ fontSize: "48px" }}>
            Nom!
          </Text>
          <Text sizing="small">
            A mobile application designed to boost restaurant business during
            slow hours.
          </Text>
          <StackList>
            <StackItem>
              <Text sizing="small">TypeScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">JavaScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">HTML</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">CSS</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">React Native</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Node.js</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">MongoDB</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">OpenAI</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Expo</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">NGINX</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Stripe</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">AWS</Text>
            </StackItem>
          </StackList>
          <LinkContainer>
            <Anchor
              href="https://github.com/langara-capstone-Spring2024/nom-fe-revamp"
              target="_blank"
            >
              <FaGithub color="midnightblue" size={32} />
            </Anchor>
            <Anchor
              href="https://github.com/dacosio/capstone_backend"
              target="_blank"
            >
              <FaGithub color="midnightblue" size={32} />
            </Anchor>
            <Anchor href="https://www.nomnomstore.biz/" target="_blank">
              <FaExternalLinkAlt color="midnightblue" size={32} />
            </Anchor>
          </LinkContainer>
        </MessageContainer>
        <Carousel
          slides={[
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom1.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom2.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom3.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom5.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom6.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom7.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom8.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/nom9.png" />
            </ImageContainer>,
          ]}
          style={{
            transform: `translateX(${
              multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y3 - (window.innerHeight - height3) / 2) - bias
                ),
                4
              )
            }%)`,
          }}
        />
      </ProjectContainer>
      <ProjectContainer
        ref={(ref) => {
          setY2(ref?.offsetTop || 0);
          setHeight2(ref?.offsetHeight || 0);
        }}
        style={{
          gridTemplateColumns: "2fr 1fr",
          flexDirection: "column-reverse",
        }}
      >
        <Carousel
          slides={[
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/sprout1.png" />
            </ImageContainer>,
            <ImageContainer style={{ width: "100%" }}>
              <Image src="/assets/sprout2.png" style={{ width: "100%" }} />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/sprout3.png" />
            </ImageContainer>,
          ]}
          style={{
            transform: `translateX(${
              -multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y2 - (window.innerHeight - height2) / 2) - bias
                ),
                4
              )
            }%)`,
          }}
        />
        <MessageContainer
          style={{
            transform: `translateX(${
              multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y2 - (window.innerHeight - height2) / 2) + bias
                ),
                4
              )
            }%)`,
          }}
        >
          <Text bold style={{ fontSize: "48px" }}>
            Sprout
          </Text>
          <Text sizing="small">
            A web platform designed for helping new farmers and gardeners with
            special features such as crop suggestions, planting conditions, and
            yield prediction based on user input data.
          </Text>
          <StackList>
            <StackItem>
              <Text sizing="small">TypeScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">JavaScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">HTML</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">CSS</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">React</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Node.js</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Python</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Flask</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">MongoDB</Text>
            </StackItem>
          </StackList>
          <LinkContainer>
            <Anchor href="https://github.com/dacosio/project-2" target="_blank">
              <FaGithub color="midnightblue" size={32} />
            </Anchor>
            <Anchor href="https://sprout.yuhwan-ban.link/" target="_blank">
              <FaExternalLinkAlt color="midnightblue" size={32} />
            </Anchor>
          </LinkContainer>
        </MessageContainer>
      </ProjectContainer>
      <ProjectContainer
        ref={(ref) => {
          setY1(ref?.offsetTop || 0);
          setHeight1(ref?.offsetHeight || 0);
        }}
        style={{ gridTemplateColumns: "1fr 2fr", flexDirection: "column" }}
      >
        <MessageContainer
          style={{
            transform: `translateX(${
              -multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y1 - (window.innerHeight - height1) / 2) + bias
                ),
                4
              )
            }%)`,
          }}
        >
          <Text bold style={{ fontSize: "48px" }}>
            SplitShare
          </Text>
          <Text sizing="small">
            PWA to split and share bulk items to save budget
          </Text>
          <StackList>
            <StackItem>
              <Text sizing="small">JavaScript</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">HTML</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">CSS</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">React</Text>
            </StackItem>
            <StackItem>
              <Text sizing="small">Firebase</Text>
            </StackItem>
          </StackList>
          <LinkContainer>
            <Anchor
              href="https://github.com/dacosio/integrated-project"
              target="_blank"
            >
              <FaGithub color="midnightblue" size={32} />
            </Anchor>
            <Anchor
              href="https://splitshare-integrated.vercel.app/"
              target="_blank"
            >
              <FaExternalLinkAlt color="midnightblue" size={32} />
            </Anchor>
          </LinkContainer>
        </MessageContainer>
        <Carousel
          slides={[
            <ImageContainer style={{ width: "100%" }}>
              <Image src="/assets/splitshare1.png" style={{ width: "100%" }} />
            </ImageContainer>,
            <ImageContainer style={{ width: "100%" }}>
              <Image src="/assets/splitshare2.png" style={{ width: "100%" }} />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/splitshare3.png" />
            </ImageContainer>,
            <ImageContainer style={{ height: "360px" }}>
              <Image src="/assets/splitshare4.png" />
            </ImageContainer>,
          ]}
          style={{
            transform: `translateX(${
              multiplier *
              Math.pow(
                Math.abs(
                  scrollY - (y1 - (window.innerHeight - height1) / 2) - bias
                ),
                4
              )
            }%)`,
          }}
        />
      </ProjectContainer>
    </Container>
  );
};

export default React.memo(ProjectView);
