import React from "react";
import { useMediaQuery } from "react-responsive";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import emailjs from "@emailjs/browser";

import { ContactProps } from "./Contact.props";
import {
  Anchor,
  Container,
  FormContainer,
  LinkContainer,
  MessageContainer,
} from "./Contact.style";
import Text from "../../component/Text";
import InputText from "../../component/InputText";
import Button from "../../component/Button";
import InputTextArea from "../../component/InputTextArea";

type Form = {
  name: string;
  email: string;
  message: string;
};

const ContactView = (props: ContactProps): JSX.Element => {
  const { style } = props;

  const isDesktopDevice = useMediaQuery({ minWidth: 992 });

  const initialValues: Form = {
    email: "",
    name: "",
    message: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Your email is required"),
    name: Yup.string().required("Your name is required"),
    message: Yup.string().required("Your message is required"),
  });

  const handleSubtmit = async (
    values: Form,
    formikHelpers: FormikHelpers<{
      email: string;
      name: string;
      message: string;
    }>
  ) => {
    if (
      process.env.REACT_APP_EMAILJS_SERVICE_ID &&
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID
    ) {
      try {
        const response = await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          { email: values.email, name: values.name, message: values.message },
          {
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
          }
        );

        if (response.status === 200) {
          toast.success("Message sent successfully");
          formikHelpers.resetForm({ values: initialValues });
        } else {
          toast.error("Message failed to send. Please retry");
        }
      } catch (error) {
        toast.error("Message failed to send. Please retry");
      }
    }
  };

  return (
    <Container id="contact" style={style}>
      <MessageContainer
        style={{ justifySelf: isDesktopDevice ? "flex-end" : "center" }}
      >
        <Text>Yuhwan Lucas Ban</Text>
        <Anchor href="mailto:laubana@gmail.com">
          <Button color="transparent">laubana@gmail.com</Button>
        </Anchor>
        <Button
          onClick={() => {
            window.open("http://resume.yuhwan-ban.link");
          }}
        >
          Get Resume
        </Button>
        <LinkContainer>
          <Anchor
            href="https://www.linkedin.com/in/yuhwan-lucas-ban/"
            target="_blank"
          >
            <FaLinkedin color="midnightblue" size={32} />
          </Anchor>
          <Anchor href="https://github.com/laubana" target="_blank">
            <FaGithub color="midnightblue" size={32} />
          </Anchor>
        </LinkContainer>
      </MessageContainer>
      <FormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubtmit}
        >
          {({ handleSubmit, values, setFieldValue, touched, errors }) => (
            <>
              <InputText
                placeholder="Email"
                text={values.email}
                setText={(text) => {
                  setFieldValue("email", text);
                }}
                error={(touched.email && errors.email) || ""}
                sizing="large"
              />
              <InputText
                placeholder="Name"
                text={values.name}
                setText={(text) => {
                  setFieldValue("name", text);
                }}
                error={(touched.name && errors.name) || ""}
                sizing="large"
              />
              <InputTextArea
                placeholder="Message"
                text={values.message}
                setText={(text) => {
                  setFieldValue("message", text);
                }}
                error={(touched.message && errors.message) || ""}
                sizing="large"
              />
              <Button onClick={handleSubmit}>Submit</Button>
            </>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
};

export default React.memo(ContactView);
