import React from "react";
import { SkillProps } from "./Skill.props";
import { Container } from "./Skill.style";
import { FaAws, FaJava, FaPython, FaReact, FaWordpress } from "react-icons/fa";
import {
  SiTypescript,
  SiJavascript,
  SiHtml5,
  SiCss3,
  SiSpring,
  SiMongodb,
  SiPostgresql,
  SiGit,
  SiFirebase,
  SiNextdotjs,
  SiDocker,
  SiKubernetes,
  SiNodedotjs,
  SiGraphql,
  SiExpress,
  SiTailwindcss,
  SiBootstrap,
  SiJquery,
  SiRedux,
  SiOracle,
  SiLinux,
} from "react-icons/si";

const SkillView = (props: SkillProps): JSX.Element => {
  const { style } = props;

  return (
    <Container id="skill" style={style}>
      <FaJava size={64} color="midnightblue" />
      <SiTypescript size={64} color="midnightblue" />
      <SiJavascript size={64} color="midnightblue" />
      <SiHtml5 size={64} color="midnightblue" />
      <SiCss3 size={64} color="midnightblue" />
      <FaPython size={64} color="midnightblue" />
      <SiGraphql size={64} color="midnightblue" />
      <FaReact size={64} color="midnightblue" />
      <SiNextdotjs size={64} color="midnightblue" />
      <SiNodedotjs size={64} color="midnightblue" />
      <SiExpress size={64} color="midnightblue" />
      <SiSpring size={64} color="midnightblue" />
      <SiRedux size={64} color="midnightblue" />
      <SiTailwindcss size={64} color="midnightblue" />
      <SiBootstrap size={64} color="midnightblue" />
      <SiJquery size={64} color="midnightblue" />
      <SiMongodb size={64} color="midnightblue" />
      <SiOracle size={64} color="midnightblue" />
      <SiPostgresql size={64} color="midnightblue" />
      <SiFirebase size={64} color="midnightblue" />
      <FaAws size={64} color="midnightblue" />
      <SiDocker size={64} color="midnightblue" />
      <SiKubernetes size={64} color="midnightblue" />
      <SiGit size={64} color="midnightblue" />
      <SiLinux size={64} color="midnightblue" />
      <FaWordpress size={64} color="midnightblue" />
    </Container>
  );
};

export default React.memo(SkillView);
