import styled from "@emotion/styled";

export const Container = styled.div`
  display: grid;
  gap: 32px;
`;

export const AboutHeadContainer = styled.div`
  margin: 64px 0;
`;

export const ImageContainer = styled.div``;

export const Image = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top 10%;
  aspect-ratio: 1;
`;

export const MessageContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const AboutFootContainer = styled.div``;

export const ListContainer = styled.div`
  display: grid;
  gap: 32px;
`;

export const ItemContainer = styled.div`
  display: grid;
  gap: 16px;
`;

export const ItemHeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 992px) {
    & {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const ItemFootContainer = styled.div``;
