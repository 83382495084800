import React from "react";
import { HomeProps } from "./Home.props";
import { Container, QrCode } from "./Home.style";
import Text from "../../component/Text";
import { useMediaQuery } from "react-responsive";
import Button from "../../component/Button";

const HomeView = (props: HomeProps): JSX.Element => {
  const { style } = props;

  const isDesktopDevice = useMediaQuery({ minWidth: 992 });

  return (
    <Container id="home" style={style}>
      <Text
        alignment="center"
        bold
        style={{
          fontSize: isDesktopDevice ? "48px" : "32px",
          letterSpacing: 2,
        }}
      >
        Hello, I'm Yuhwan(Lucas)!
      </Text>
      <Text alignment="center">Full Stack / Java Developer</Text>
      <QrCode src="/assets/qrcode.png" />
      <Button
        sizing="large"
        onClick={() => {
          window.open("http://resume.yuhwan-ban.link");
        }}
      >
        Get Resume
      </Button>
    </Container>
  );
};

export default React.memo(HomeView);
